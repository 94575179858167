<template>
  <button
    class="button-text"
    :class="{
      'centered': centered
    }"
    @click="$emit('onclick')"
  >
    {{ text }}

    <svg
      v-if="loaded"
      width="24"
      height="24"
      style="min-width: 24px; min-height: 24px;"
      class="fill"
      :class="{
        animate: loading
      }"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.43671 3.36955C7.41007 3.97195 5.66021 5.26862 4.494 7.0322C4.33384 7.32118 4.23382 7.63955 4.2 7.9682V4.80621H3V9.6062H7.79999V8.4062H5.088C6.43154 5.82152 9.08721 4.18476 12 4.14621C15.4543 4.18637 18.4821 6.46573 19.476 9.7742L20.628 9.4322C19.2117 4.66764 14.2013 1.95332 9.43671 3.36955Z" fill="#202539"/>
      <path d="M16.1991 14.4063V15.6063H18.9111C17.5675 18.191 14.9118 19.8277 11.9991 19.8663C8.54477 19.8261 5.51699 17.5468 4.52309 14.2383L3.37109 14.5803C4.79339 19.343 9.80736 22.0509 14.5701 20.6286C16.6561 20.0057 18.4457 18.6477 19.6071 16.8063C19.7315 16.5715 19.7974 16.31 19.7991 16.0443V19.2063H20.9991V14.4063H16.1991Z" fill="#202539"/>
    </svg>

    <svg
      v-else
      :class="{
        bottom: toBottom
      }"
      width="9"
      height="14"
      style="min-width: 9px; min-height: 14px;"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L7 7L1 13" stroke="#202539" stroke-width="2" stroke-linecap="round"/>
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    toBottom: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    },
    loaded: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  height: 36px;
  width: 100%;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #D5DDFB;
  border-radius: 20px;
  border: none;
  color: rgba(var(--color-dark));
  font-family: SemiBold;
  transition: .2s;

  &.centered {
    justify-content: center;

    svg {
      display: none;
    }
  }

  &.h46 {
    height: 46px;
    border-radius: 30px;
  }

  &.blue {
    background: rgba(var(--primary-color));
    color: #fff;

    svg {
      &.fill path {
        stroke: transparent !important;
        fill: white;
      }

      path {
        stroke: white;
      }
    }

    &:hover {
      background: rgb(var(--color-light-blue));
    }

    &:active {
      background: rgba(var(--color-dark));
    }

    &:disabled {
      background: rgba(var(--primary-color), .1);
      color: rgba(var(--text-color-light));
      cursor: not-allowed;
    }
  }

  svg {
    &.animate {
      animation: rotate 1s infinite;
    }

    &.bottom {
      transform: rotate(90deg);
    }
  }

  svg path {
    transition: .2s;
  }

  &:hover {
    background: rgb(var(--color-light-blue));
    color: white;

    svg {
      &.fill path {
        stroke: transparent !important;
        fill: white;
      }

      path {
        stroke: white;
      }
    }
  }

  &:active {
    background: rgba(var(--color-dark));
    color: white;

    svg {
      &.fill path {
        stroke: transparent !important;
        fill: white;
      }

      path {
        stroke: white;
      }
    }
  }

  &:disabled {
    background: rgba(var(--primary-color), .1);
    color: rgba(var(--text-color-light));
    cursor: not-allowed;

    svg path {
      stroke: rgba(var(--text-color-light));

      &.fill {
        stroke: transparent !important;
        fill: rgba(var(--text-color-light));
      }
    }

    svg {
      &.fill path {
        stroke: transparent !important;
        fill: rgba(var(--text-color-light));
      }

      path {
        stroke: rgba(var(--text-color-light));
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(-0deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}
</style>
