<template>
  <button
    @click="$route.matched.length === 1 ? $router.push({ name: 'Main' }) : back()"
  >
    <svg
      width="9" height="14"
      style="min-width: 9px; min-height: 14px;"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 13L2 7L8 1" stroke-width="2" stroke-linecap="round"/>
    </svg>

    {{ $t('components.ui.buttons.back') }}
  </button>
</template>

<script>
export default {
  methods: {
    back () {
      try {
        const pathLocale = this.$route.path.split('/')[1] || ''

        if (window.history.state.back === this.$route.fullPath) {
          this.$router.push({ name: 'Main' })
        } else {
          this.$router.replace({
            path: window.history.state.back,
            params: Object.assign({}, this.$route.params, { locale: pathLocale })
          })
        }
      } catch {
        this.$router.push({ name: 'Main' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  button {
    display: flex;
    align-items: center;
    color: rgba(var(--text-color-light-gray));
    font-size: 16px;
    font-family: SemiBold;
    transition: .2s;

    svg {
      margin-top: 2px;
      margin-right: 14px;

      path {
        stroke: #787C8D;
        transition: .2s;
      }
    }

    &.white {
      color: white;

      svg {
        path {
          stroke: white;
        }
      }
    }

    &:hover {
      color: rgba(var(--primary-color));

      svg path {
        stroke: rgba(var(--primary-color));
      }
    }

    &:active {
      color: rgba(var(--color-dark));

      svg path {
        stroke: rgba(var(--color-dark));
      }
    }
  }
</style>
