<template>
  <RouterLink
    :to="{ name: 'NewsSelect', params: { slug: value.slug } }"
    class="card"
  >
    <img :src="value.image_url">

    <div class="card__content">
      <p class="card__content__label">
        {{ value.name }}
      </p>

      <div class="card__content__info">
        <p class="card__content__info__date">
          <svg
            width="14" height="14"
            style="min-width: 14px; min-height: 14px;"
            viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_945_18465)">
              <path d="M9.49347 8.23642L7.54148 6.77244V3.79027C7.54148 3.49042 7.29911 3.24805 6.99926 3.24805C6.69941 3.24805 6.45703 3.49042 6.45703 3.79027V7.04358C6.45703 7.21437 6.53728 7.37542 6.67392 7.47736L8.84277 9.10401C8.94036 9.17721 9.05425 9.21246 9.16756 9.21246C9.33294 9.21246 9.49561 9.13816 9.60189 8.99502C9.78195 8.75587 9.73314 8.41591 9.49347 8.23642Z" fill="#787C8D"/>
              <path d="M7 0C3.13996 0 0 3.13996 0 7C0 10.86 3.13996 14 7 14C10.86 14 14 10.86 14 7C14 3.13996 10.86 0 7 0ZM7 12.9156C3.73857 12.9156 1.08443 10.2614 1.08443 7C1.08443 3.73857 3.73857 1.08443 7 1.08443C10.262 1.08443 12.9156 3.73857 12.9156 7C12.9156 10.2614 10.2614 12.9156 7 12.9156Z" fill="#787C8D"/>
            </g>
            <defs>
              <clipPath id="clip0_945_18465">
                <rect width="14" height="14" fill="white"/>
              </clipPath>
            </defs>
          </svg>

          {{ $format.date(value.publish_at) }}
        </p>

        <button class="card__content__info__button">
          <svg
            width="9" height="14"
            style="min-width: 9px; min-height: 14px;"
            viewBox="0 0 9 14" fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L7 7L1 13" stroke="#202539" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </button>
      </div>
    </div>
  </RouterLink>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
  border-radius: 15px;
  text-decoration: none;
  overflow: hidden;

  img {
    height: 210px;
    max-height: 210px;
    min-height: 210px;
    width: 100%;
    object-fit: cover;
  }

  .card__content {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p.card__content__label {
      color: rgb(var(--color-dark));
      font-family: SemiBold;
      font-size: 18px;
      line-height: 150%;
      text-decoration: none;
    }

    .card__content__info {
      margin-top: 5px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .card__content__info__date {
        display: flex;
        align-items: center;
        color: rgba(var(--text-color-light-gray));
        font-family: Medium;
        font-size: 12px;
        line-height: 100%;

        svg {
          margin-right: 4px;
        }
      }

      button.card__content__info__button {
        height: 36px;
        width: 36px;
        min-width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(var(--primary-color), .2);
        border-radius: 50%;
        transition: .2s;

        svg {
          margin-left: 3px;
        }

        svg path {
          transition: .2s;
        }

        &:hover {
          background: #577FFF;

          svg path {
            stroke: white;
          }
        }

        &:active {
          background: #586BB1;

          svg path {
            stroke: white;
          }
        }
      }
    }
  }
}
</style>
