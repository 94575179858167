<template>
  <UiPage
    :title="$t('app.page.news')"
    :titleTemplate="$t('app.page.news')"
    :is-top="false"
    class="custom-page__style"
  >
    <template #content>
      <div class="top">
        <img :src="data.image_url">

        <div class="top__content">
          <div class="top__content__container">
            <div class="top__content__container__info">
              <div
                v-if="!$store.state.window.to768"
                class="top__content__container__info__date"
              >
                <svg
                  width="14" height="14"
                  style="min-width: 14px; min-height: 14px;"
                  viewBox="0 0 14 14" fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_945_18727)">
                    <path d="M9.49347 8.23642L7.54148 6.77244V3.79027C7.54148 3.49042 7.29911 3.24805 6.99926 3.24805C6.69941 3.24805 6.45703 3.49042 6.45703 3.79027V7.04358C6.45703 7.21437 6.53728 7.37542 6.67392 7.47736L8.84277 9.10401C8.94036 9.17721 9.05425 9.21246 9.16756 9.21246C9.33294 9.21246 9.49561 9.13816 9.60189 8.99502C9.78195 8.75587 9.73314 8.41591 9.49347 8.23642Z" fill="white"/>
                    <path d="M7 0C3.13996 0 0 3.13996 0 7C0 10.86 3.13996 14 7 14C10.86 14 14 10.86 14 7C14 3.13996 10.86 0 7 0ZM7 12.9156C3.73857 12.9156 1.08443 10.2614 1.08443 7C1.08443 3.73857 3.73857 1.08443 7 1.08443C10.262 1.08443 12.9156 3.73857 12.9156 7C12.9156 10.2614 10.2614 12.9156 7 12.9156Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_945_18727">
                      <rect width="14" height="14" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>

                {{ $format.date(data.publish_at) }}
              </div>

              <BackButton class="white" />
            </div>

            <h2>
              <p
                v-if="$store.state.window.to768"
                class="time"
              >
                <svg
                  width="14" height="14"
                  style="min-width: 14px; min-height: 14px;"
                  viewBox="0 0 14 14" fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_945_18727)">
                    <path d="M9.49347 8.23642L7.54148 6.77244V3.79027C7.54148 3.49042 7.29911 3.24805 6.99926 3.24805C6.69941 3.24805 6.45703 3.49042 6.45703 3.79027V7.04358C6.45703 7.21437 6.53728 7.37542 6.67392 7.47736L8.84277 9.10401C8.94036 9.17721 9.05425 9.21246 9.16756 9.21246C9.33294 9.21246 9.49561 9.13816 9.60189 8.99502C9.78195 8.75587 9.73314 8.41591 9.49347 8.23642Z" fill="white"/>
                    <path d="M7 0C3.13996 0 0 3.13996 0 7C0 10.86 3.13996 14 7 14C10.86 14 14 10.86 14 7C14 3.13996 10.86 0 7 0ZM7 12.9156C3.73857 12.9156 1.08443 10.2614 1.08443 7C1.08443 3.73857 3.73857 1.08443 7 1.08443C10.262 1.08443 12.9156 3.73857 12.9156 7C12.9156 10.2614 10.2614 12.9156 7 12.9156Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_945_18727">
                      <rect width="14" height="14" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>

                {{ $format.date(data.publish_at) }}
              </p>

              {{ data.name }}
            </h2>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="content__padding">
          <div class="content__padding__container">
            <Html
              v-if="!loading"
              :value="data.text"
              class="content__padding__container__text"
            />

            <!-- <div
              class="content__padding__container__text"
              v-html="data.text"
            /> -->
          </div>
        </div>
      </div>

      <div class="list">
        <h3>
          {{ $t('views.default.news.select.other-news') }}
        </h3>

        <div
          class="list__grid"
          :style="{
            'grid-template-columns': `repeat(${_columns}, 1fr)`
          }"
        >
          <template v-if="!preloader">
            <NewsCard
              v-for="item in otherNews"
              :key="item"
              :value="item"
            />
          </template>

          <template v-else>
            <div
              v-for="item in 4"
              :key="item"
              class="grid-preloader-card"
            >
              <Preloader
                :visible="preloader"
              />
            </div>
          </template>
        </div>

        <div class="list__control">
          <LightButton
            style="max-width: 250px;"
            :text="$t('views.default.news.select.all-news')"
            @click="$router.push({ name: 'NewsList' })"
          />
        </div>
      </div>
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import BackButton from '@/ui/buttons/BackButton.vue'
import Html from '@/ui/Html.vue'
import NewsCard from '@/ui/NewsCard.vue'
import Preloader from '@/ui/preloader/SkeletonAnimatePreloader.vue'
import LightButton from '@/ui/buttons/LightButton.vue'

export default {
  components: {
    UiPage,
    BackButton,
    Html,
    NewsCard,
    Preloader,
    LightButton
  },
  data () {
    return {
      preloader: true,
      loading: true,
      length: 4,
      data: {},
      otherNews: []
    }
  },
  computed: {
    _columns () {
      return this.$store.state.window.to768 ? 1
        : this.$store.state.window.width <= 1570 && this.$store.state.window.width >= 1280 ? 3
          : this.$store.state.window.width < 1280 ? 2 : this.length
    },
    _slug () {
      return this.$route.params.slug || this.$route.path.split('/')[3]
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        this.loading = true

        const resp = await this.$axios.get(
          `/private/news/${this._slug}`)

        if (resp.status === 200) {
          this.data = resp.data.data

          const respOther = await this.$axios.get(
          `/private/news?limit=4&not_equal=${this.data.id}`)

          if (respOther.status === 200) {
            this.otherNews = respOther.data.data
          }
        }
      } catch (err) {
        this.$store.commit('SET_ERROR', true)
        throw new Error(err)
      } finally {
        this.preloader = false
        this.loading = false
      }
    }
  },
  watch: {
    '$route.params.slug' () {
      if (this.$route.params.slug) {
        this.fetch()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-page__style {
  margin: 0;
  padding: 0;
}

.top {
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .top__content {
    position: relative;
    width: 100%;
    padding: 0 40px;
    background: rgba(0, 0, 0, .4);
    z-index: 2;

    .top__content__container {
      height: 540px;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 40px 0 50px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .top__content__container__info {
        display: flex;
        justify-content: space-between;

        .top__content__container__info__date {
          display: flex;
          align-items: center;
          color: white;
          font-family: Medium;
          font-size: 12px;
          line-height: 100%;

          svg {
            margin-right: 4px;
          }
        }
      }

      h2 {
        max-width: 900px;
        color: white;
        font-family: SemiBold;
        line-height: 100%;
      }
    }
  }
}

.content {
  width: 100%;
  margin-top: 50px;
  padding: 0 10px;

  .content__padding {
    padding: 0 40px 50px;
    border-bottom: 1px solid rgba(var(--border-line-color));

    .content__padding__container {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;

      .content__padding__container__text {
        width: 100%;
        max-width: 900px;
        margin-right: 40px;
      }
    }
  }
}

.list {
  width: 100%;
  margin-top: 100px;
  padding: 0 40px;

  h3 {
    margin-bottom: 30px;
    line-height: 100%;
    color: rgb(var(--color-black));
    font-family: SemiBold;
  }

  .list__grid {
    margin-bottom: 50px;
    display: grid;
    gap: 20px;

    .grid-preloader-card {
      position: relative;
      height: 420px;
      background: #FFFFFF;
      box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.06);
      border-radius: 15px;
      overflow: hidden;
    }
  }

  .list__control {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 1024px) {
  .top {
    .top__content {
      .top__content__container {
        padding: 70px 0 20px !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .top {
    .top__content {
      padding: 0 17px;

      .top__content__container {
        height: 340px;
        margin: 0 auto;
        padding: 20px 0;

        h2 {
          p.time {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            font-family: Thin !important;
            font-size: 12px;
            line-height: 100%;

            svg {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    margin-top: 30px;
    padding: 0 17px;

    .content__padding {
      padding: 0 0 40px;

      .content__padding__container {
        .content__padding__container__text {
          margin-right: 0 !important;
        }
      }
    }
  }

  .list {
    margin-top: 40px;
    padding: 0 17px 100px;

    h3 {
      margin-bottom: 15px;
    }

    .list__grid {
      margin-bottom: 40px;
    }

    .list__control {
      button {
        max-width: none !important;
      }
    }
  }
}
</style>
