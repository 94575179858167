<template>
  <Transition>
    <div
      v-if="visible"
      class="animate-skeleton-preloader"
    />
  </Transition>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
.animate-skeleton-preloader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  border-radius: inherit;
  z-index: 100;
  overflow: hidden;

  &.black {
    background: #0E0E1B;

    &::before {
      background: linear-gradient(to right, transparent 0%, rgba(15, 191, 230, .3) 50%, transparent 100%);
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: -10%;
    height: 120%;
    width: 150px;
    transform: rotate(5deg);
    background: linear-gradient(to right, transparent 0%, rgb(var(--layout-color)) 50%, transparent 100%);
    animation: skeletonPreloader 2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }
}

@keyframes skeletonPreloader {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
</style>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity .5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
