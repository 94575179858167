<template>
  <div
    class="html"
    ref="html"
    v-html="value"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.parseMethod()
  },
  methods: {
    parseMethod () {
      const html = this.$refs.html
      const aTag = html?.querySelectorAll('a')

      /**
       * Find presentation banner
      **/
      for (const el in aTag) {
        const item = aTag[el]

        if (item.innerText && item.innerText?.indexOf('__presentation-banner::') !== -1) {
          const link = item.href
          const text = item.innerText
          const data = text.split('::')
          const json = this.parseArrayToJson(data)

          // eslint-disable-next-line prefer-const
          let div = document.createElement('div')
          div.className = 'presentation-banner'
          div.innerHTML = `
            <div class="presentation-banner__start">
              <h4>
                ${json.title || ''}
              </h4>

              <p>
                ${json.text || ''}
              </p>
            </div>

            <div class="presentation-banner__end">
              <a href="${link}" target="_blank">
                ${json.button || ''}
              </a>
            </div>
          `

          item.replaceWith(div)
        }
      }
    },
    parseArrayToJson (value) {
      var data = {}

      for (const el in value) {
        const item = value[el]

        if (item.indexOf(':') !== -1) {
          const index = item.search(':')
          var key = item.slice(0, index)
          var val = item.slice(index + 1, item.length)

          data[key] = val
        }
      }

      return data
    }
  }
}
</script>

<style lang="scss">
.html {
  * {
    line-height: 150%;
  }

  a {
    font-family: SemiBold;
    color: #000;
  }

  strong {
    font-family: Bold;
  }

  img {
    height: auto !important;
    width: auto !important;
    max-width: 100%;
    object-fit: contain;
  }

  i, em {
    font-style: italic;
  }

  ul, ol {
    margin: 0;
    padding-left: 25px;
  }

  p.ql-align-center {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    text-align: center;

    span {
      width: auto !important;
    }
  }

  iframe {
    width: 100%;
    aspect-ratio: 16 / 9;

    @supports not (aspect-ratio: 16 / 9) {
      &::before {
        float: left;
        padding-top: 56.25%;
        content: '';
      }

      &::after {
        display: block;
        content: '';
        clear: both;
      }
    }
  }
}
</style>

<style lang="scss">
.presentation-banner {
  width: 100%;
  margin: 20px 0;
  padding: 40px 30px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(94.38deg, rgba(14, 14, 27, .3) 0%, rgba(47, 87, 233, .3) 100%), #0E0E1B;
  border-radius: 15px;

  .presentation-banner__start {
    width: 100%;

    h4 {
      font-size: 18px;
      line-height: 100%;
      color: #FFFFFF;
      font-family: SemiBold;
    }

    p {
      margin-top: 10px;
      font-size: 13px;
      line-height: 150%;
      color: #FFFFFF;
      opacity: .8;
    }
  }

  .presentation-banner__end {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    a {
      height: 46px;
      width: 100%;
      max-width: 270px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #2F57E9;
      border-radius: 30px;
      font-size: 14px;
      line-height: 100%;
      text-transform: uppercase;
      text-decoration: none;
      color: #FFFFFF;
      font-family: SemiBold;
      transition: .2s;

      &:hover {
        opacity: .8;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .presentation-banner {
    padding: 40px 30px;
  }
}

@media screen and (max-width: 768px) {
  .presentation-banner {
    padding: 30px 20px;
    flex-direction: column;

    .presentation-banner__start {
      p {
        margin-top: 30px;
      }
    }

    .presentation-banner__end {
      margin-top: 20px;
      justify-content: flex-start;
    }
  }
}

@media screen and (max-width: 430px) {
  .presentation-banner {
    .presentation-banner__end {
      a {
        max-width: 100%;
      }
    }
  }
}
</style>
